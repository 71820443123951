export default function Social() {
  return (
    <section id="social">
      <div className="btn-facebook">
        <a
          href="https://www.facebook.com/afroditamodelstudio"
          target="_blank" rel="noreferrer"
        >
          <img src="/img/facebook.png" alt="" />
        </a>
      </div>
      <div className="btn-twitter">
        <a
          href="https://x.com/afroditamodels"
          target="_blank" rel="noreferrer"
        >
          <img src="/img/twitter.png" alt="" />
        </a>
      </div>
      <div className="btn-instagram">
        <a
          href="https://www.instagram.com/afroditamodelsoficial/"
          target="_blank" rel="noreferrer"
        >
          <img src="/img/instagram.png" alt="" />
        </a>
      </div>
      <div className="btn-whatsapp">
        <a
          href={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)?'https://api.whatsapp.com/send/?phone=%2B573226707089&text&type=phone_number&app_absent=0':'https://wa.me/573226707089'}
          target="_blank" rel="noreferrer"
        >
          <img src="/img/whatsapp.png" alt="" />
        </a>
      </div>
    </section>
  );
}
