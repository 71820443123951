// componentes
import Hero from "../components/components-home/Hero";
import Header from "../components/components-home/header/Header";
import Abount from "../components/components-home/About";
import Services from "../components/components-home/Services";
import Portfolio from "../components/components-home/Portfolio";
import Team from "../components/components-home/Team";
import Contact from "../components/components-home/Contact";
import Footer from "../components/components-home/Footer";
import Social from "../components/components-home/Social";

import "./../assets/style/index.css";
import AnimationMouse from "../components/shared/AnimationMouse";
import ModalImag from "../components/shared/ModalImag";
export default function App() {
  return (
    <>
      <Header />
      <Hero />
      <Abount />
      <Services />
      <Portfolio />
      <Team />
      <Contact />
      <Footer />
      <Social />
      <AnimationMouse/>
      <ModalImag/>
    </>
  );
}
