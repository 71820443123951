import { createContext, useState } from "react";

export const ShoppingCartContext = createContext();
export const ShoppingCartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);

    const getProduct = (product) => {
        return cart.find(item => item.id === product.id);
    }

    const addProduct = (product) => {
        const selectProduct = getProduct(product);
        if (selectProduct) {
            const cartUpdate = cart.map(item => {
                if (item.id === product.id && item.quantity + 1 <= product.quantity) {
                    item.quantity = item.quantity + 1;
                    return item;
                }
                return item;
            })
            setCart(cartUpdate)
        } else if (product.quantity > 0) {
            setCart([...cart,{ ...product, quantity: 1 }])
        }
    }

    const deleteProduct = (product) => {
        const selectProduct = getProduct(product);
        if (selectProduct) {
            const cartUpdate = cart.map(item => {
                if (item.id === product.id && item.quantity - 1 >= 0) {
                    item.quantity = item.quantity - 1;
                    return item;
                }
                return item;
            })
            setCart(cartUpdate);
        }
    }

    const clearCart = () => {
        setCart([]);
    }

    const getCart = () => {
        return cart;
    }

    return <ShoppingCartContext.Provider value={{
        getProduct,
        addProduct,
        deleteProduct,
        clearCart,
        getCart
    }}>
        {children}
    </ShoppingCartContext.Provider>
};