import backgroundImg from './../../assets/img/logo.gif'

export default function Hero() {
  return (
    <section id="hero">
      <div className="hero-container">
        <img src={backgroundImg}></img>
      </div>
    </section>
  );
}
