export default function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="copyright">
              <a href="https://cabuweb.com/">
                &copy; <strong>CABUWEB</strong>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
