import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import "swiper/swiper.min.css";
import 'swiper/modules/effect-fade/effect-fade';
import "swiper/modules/navigation/navigation";
import "swiper/modules/pagination/pagination";


export default function Portfolio() {
  return (
    <section id="portfolio">
      <div className="container wow fadeInUp">
        <div className="row">
          <div className="col-md-12">
            <h3 className="section-title">Galería</h3>
            <div className="section-title-divider"></div>
          </div>
        </div>
        <div className="row">
        <Swiper         
        effect={'coverflow'}
        grabCursor={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay:3000,
          disableOnInteraction: true
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        className="mySwiper"
        >
          {Array.from({length:15}).map((item,index)=>{
            return         <SwiperSlide key={index}>
            <img src={`/img/models/${index+1}.png`} />
          </SwiperSlide>
          })}
      </Swiper>
        </div>
      </div>
    </section>
  );
}
