import { createContext, useState } from "react";

export const ShowInfoModalContext = createContext();
export const ShowInfoModalProvider = ({ children }) => {
    const [info, setInfo] = useState({name:'',description:''});
    const [openModal, setOpenModal] = useState(false);

    return <ShowInfoModalContext.Provider value={{
        info,
        setInfo,
        openModal, 
        setOpenModal
    }}>
        {children}
    </ShowInfoModalContext.Provider>
};