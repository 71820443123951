import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from './../../../assets/img/logo.jpg';
import Cart from './components/cart/Cart';
import { useEffect , useRef} from 'react';
export default function Header() {
  const item1 = useRef(null);
  useEffect(()=>{
    item1.current.click();
  },[])
  return (
    <header id="header">
      <Navbar expand="lg" className="custom-nav">
        <Container>
          <Navbar.Brand href="#home">
            <div id="logo" className="pull-left">
              <img src={logo} alt="" title="" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav className='nav-menu'>
              <Nav.Link  href="#hero" ref={item1}>Inicio</Nav.Link>
              <Nav.Link  href="#about">Nosotros</Nav.Link>
              <Nav.Link  href="#services">Servicios</Nav.Link>
              <Nav.Link  href="#portfolio">Galería</Nav.Link>
              <Nav.Link  href="#team">Equipo</Nav.Link>
              <Nav.Link href="#contact">Contacto</Nav.Link>
              <Navbar.Brand >
                <Cart ></Cart>
              </Navbar.Brand>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
