import { useContext, useState } from 'react';
import './cart.css'
import { ShoppingCartContext } from '../../../../../context/shoppingCartContext';
export default function Cart() {

    const [showCart, setShowCart] = useState(false);

    const url_store = process.env.REACT_APP_STORAGE_URL;
    const { getCart } = useContext(ShoppingCartContext);
    const products = getCart();
    return products.length > 0 && <>
        <div onClick={() => setShowCart(!showCart)} className='pointer px-3'>
            <i className="fa fa-shopping-cart text-light"></i><span className="badge">{products.reduce((suma, item) => suma + item.quantity, 0)}</span>
        </div>
        {showCart && <div className="container-cart d-none d-lg-block" id='shopping-cart'>
            <div className="shopping-cart">
                <div className="shopping-cart-header">
                    <div className="shopping-cart-total">
                        <span className="lighter-text">Total:</span>
                        <span className="main-color-text">${products.reduce((suma, item) => suma + (item.quantity * item.value), 0)}</span>
                    </div>
                </div>
                <ul className="shopping-cart-items">
                    {
                        products.map(item =>
                            <li className="clearfix">
                                <img className='img-item-cart' src={item.images.length > 0 ? `${url_store}/${item.images[0].url}` : "img/box.jpg"} alt="item1" />
                                <span className="item-name">{item.name}</span>
                                <span className="item-price">{item.quantity * item.value}</span>
                                <span className="item-quantity">Cantidad: {item.quantity}</span>
                            </li>
                        )
                    }
                </ul>
                <div className='d-flex justify-content-center p-3'>
                    <button className="btn btn-checkout px-3">Pagar</button>
                </div>
            </div>
        </div>}
    </>;
}