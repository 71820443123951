import { ShoppingCartProvider } from "./shoppingCartContext";
import { ShowInfoModalProvider } from "./showInfoModal";

const MultiProvider = ({ children }) => {
    return <ShoppingCartProvider value={{}}>
        <ShowInfoModalProvider value={{}}>
            {children}
        </ShowInfoModalProvider>
    </ShoppingCartProvider>
}

export default MultiProvider;