export default function Services() {
  return (
    <section id="services">
      <div className="container wow fadeInUp">
        <div className="row">
          <div className="col-md-12">
            <h3 className="section-title">servicios</h3>
            <div className="section-title-divider"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-4 service-item">
            <div className="service-icon">
              <i className="fa fa-desktop"></i>
            </div>
            <h4 className="service-title">
              TECNOLOGÍA
            </h4>
            <p className="service-description">
            En <b>Afrodita Models</b>, nos enorgullece estar a la vanguardia tecnológica para ofrecer a nuestros modelos las mejores herramientas del mercado. Contamos con equipos de última generación, incluyendo cámaras de alta definición, iluminación profesional, y conexiones de internet ultrarrápidas para asegurar la mejor calidad de transmisión, garantizando una experiencia fluida y segura, tanto para nuestros modelos como para los usuarios.
            </p>
          </div>
          <div className="col-12 col-lg-4 service-item">
            <div className="service-icon">
              <i className="fa fa-usd px-2"></i>
            </div>
            <h4 className="service-title">
              PAGO
            </h4>
            <p className="service-description">
            En <b>Afrodita Models</b>, entendemos que la transparencia y la puntualidad en los pagos son esenciales para nuestros modelos. Por ello, ofrecemos pagos semanales y quincenales, asegurando que recibas tus ingresos de manera rápida y segura. Además, contamos con un sistema de porcentajes escalables que van desde el 55% hasta el 65%, para que tu esfuerzo sea recompensado de manera justa y progresiva. Confía en nosotros para brindarte un entorno profesional y confiable en todo momento.
            </p>
          </div>
          <div className="col-12 col-lg-4 service-item">
            <div className="service-icon">
              <i className="fa fa-building"></i>
            </div>
            <h4 className="service-title">
              HABITACIÓN
            </h4>
            <p className="service-description">
            Nuestras instalaciones están diseñadas pensando en la comodidad y privacidad de nuestros modelos. Disponemos de habitaciones individuales y completamente equipadas con decoración personalizada y temáticas permitiendo que cada modelo exprese su estilo único. garantizando un ambiente ideal para trabajar.
            </p>
          </div>
          <div className="col-12 col-lg-4 service-item">
            <div className="service-icon">
              <i className="fa fa-calendar"></i>
            </div>
            <h4 className="service-title">
              HORARIO
            </h4>
            <p className="service-description">
            Entendemos la importancia de la flexibilidad en los horarios de trabajo. En <b>Afrodita Models</b>, ofrecemos la posibilidad de que nuestros modelos elijan sus propios horarios, adaptándose a sus rutinas personales y necesidades. Ya sea que prefieras trabajar en la mañana, tarde o noche, nuestro estudio está disponible para ti 24/7.
            </p>
          </div>
          <div className="col-12 col-lg-4 service-item">
            <div className="service-icon">
              <i className="fa fa-map-marker px-1"></i>
            </div>
            <h4 className="service-title">
              UBICACIÓN
            </h4>
            <p className="service-description">
            Nuestro estudio está estratégicamente ubicado en la ciudad de Armenia, en una zona segura y de fácil acceso. Contamos con instalaciones modernas y cómodas, cercanas a transporte público, tiendas y otros servicios esenciales, facilitando la llegada de nuestros modelos y asegurando su bienestar.
            </p>
          </div>
          <div className="col-12 col-lg-4 service-item">
            <div className="service-icon">
              <i className="fa fa-shopping-bag"></i>
            </div>
            <h4 className="service-title">
              CAPACITACIÓN
            </h4>
            <p className="service-description">
            En <b>Afrodita Models</b>, creemos en el crecimiento continuo de nuestros modelos. Ofrecemos capacitaciones regulares en áreas como técnicas de transmisión, marketing personal, manejo de redes sociales y habilidades interpersonales. Nuestro objetivo es empoderar a nuestros modelos con las herramientas necesarias para destacar en la industria y alcanzar sus metas.
            </p>
          </div>
          <div className="col-12 col-lg-4 service-item">
            <div className="service-icon">
            <i className="fa-solid fa-satellite-dish"></i>
            </div>
            <h4 className="service-title">
            Modelos Satélites
            </h4>
            <p className="service-description">
            Para aquellos que prefieren trabajar desde casa, <b>Afrodita Models</b> ofrece un programa de modelos satélites. Proporcionamos todo el soporte técnico y logístico necesario para que nuestros modelos puedan transmitir desde la comodidad de su hogar, manteniendo los mismos estándares de calidad y profesionalismo que en nuestras instalaciones.
            </p>
          </div>
          <div className="col-12 col-lg-4 service-item">
            <div className="service-icon">
            <i className="fa-solid fa-user-group"></i>
            </div>
            <h4 className="service-title">
            Programa de Referidos
            </h4>
            <p className="service-description">
            Nuestro programa de referidos es una excelente oportunidad para nuestros modelos. Si refieres a un nuevo modelo a <b>Afrodita Models</b> y este se une a nuestro equipo, recibirás una bonificación especial. Este programa no solo recompensa tu lealtad, sino que también te permite contribuir al crecimiento de nuestra comunidad.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
