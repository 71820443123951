export default function About() {
  return (
    <section id="about">
      <div className="container wow fadeInUp">
        <div className="row">
          <div className="col-md-12">
            <h3 className="section-title">Nosotros</h3>
            <div className="section-title-divider"></div>
          </div>
        </div>
      </div>
      <div className="container about-container wow fadeInUp mt-5">
        <div className="row">
          <div className="col-lg-6 about-img">
            <img src="img/nosotros.webp" alt="" />
          </div>
          <div className="col-lg-6 about-content">
            <h2 className="about-title">Afrodita Models</h2>
            <p className="about-text">
            En <b>Afrodita Models</b>, somos un estudio de modelaje webcam ubicado en la vibrante ciudad de Armenia, con más de 4 años de experiencia en la industria. Nos especializamos en formar y guiar a modelos para que expresen su individualidad y talento en un entorno seguro, respetuoso y profesional. Nuestra pasión por la creatividad y el entretenimiento nos ha permitido construir una sólida reputación como uno de los estudios líderes en el sector, ofreciendo oportunidades de crecimiento personal y profesional a nuestros modelos.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 about-img order-inver">
            <img src="img/1.webp" alt="" />
          </div>
          <div className="col-lg-6 about-content">
            <h2 className="about-title">Misión</h2>
            <p className="about-text">
            Nuestra misión en <b>Afrodita Models</b> es proporcionar un espacio seguro y empoderador donde nuestros modelos puedan expresarse libremente, alcanzar sus metas financieras y personales, y conectar con audiencias de todo el mundo. Nos comprometemos a ofrecer un ambiente de trabajo inclusivo, apoyando el desarrollo continuo de nuestros modelos mediante formación, recursos y un apoyo constante, para que cada uno pueda alcanzar su máximo potencial.
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-6 about-img">
            <img src="img/2.webp" alt="" />
          </div>
          <div className="col-lg-6 about-content">
            <h2 className="about-title">Visión</h2>
            <p className="about-text">
            En <b>Afrodita Models</b>, aspiramos a ser el estudio de modelaje webcam líder en la región y un referente a nivel nacional, reconocido por nuestra dedicación a la excelencia, la innovación y el respeto por la diversidad. Nuestra visión es transformar la industria del entretenimiento adulto, rompiendo barreras y estereotipos, y creando un espacio donde la creatividad, la autenticidad y la diversidad sean celebradas.
            </p>
          </div>
        </div>
        <div className="row mt-5">
         <div className="col-lg-6 about-img order-inver">
            <img src="img/3.webp" alt="" />
          </div>
          <div className="col-lg-6 about-content">
            <h2 className="about-title">Diversidad e Inclusión</h2>
            <p className="about-text">
            En <b>Afrodita Models</b>, la diversidad y la inclusión son pilares fundamentales de nuestra cultura. Valoramos y respetamos las diferencias individuales, ya sea en términos de género, orientación sexual, origen étnico, o cualquier otra característica personal. Creemos que cada modelo aporta una perspectiva única que enriquece nuestra comunidad. Nos esforzamos por crear un entorno en el que todos se sientan bienvenidos, valorados y apoyados, promoviendo la igualdad de oportunidades y la justicia para todos.
Estamos comprometidos a construir una comunidad inclusiva donde la diversidad no solo se tolere, sino que se celebre como una fortaleza esencial de nuestra identidad como estudio.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
