import { useContext } from "react";
import { ShowInfoModalContext } from "../../context/showInfoModal";

export default function Team() {

  const {setInfo,setOpenModal} = useContext(ShowInfoModalContext);

  const teams = [
    {name:"Luis Muñoz",img:'img/4.webp',description:"Cofundador de Afrodita Models y profesional en Negocios Internacionales, es la mente detrás de la gestión financiera y contable del estudio. Además de asegurar la solidez financiera de Afrodita Models, Luis también es responsable de la gestión del talento humano, incluyendo la contratación y el bienestar del personal. Su enfoque integral y estratégico garantiza un entorno laboral óptimo, donde cada miembro del equipo puede desarrollarse y contribuir al crecimiento continuo del estudio."},
    {name:"Santiago Perdomo",img:'img/5.webp',description:"Licenciado en Educación Física y cofundador de Afrodita Models, es el motor detrás de la dirección estratégica y operativa del estudio. Como director general, Santiago no solo supervisa las actividades diarias, sino que también impulsa la expansión y el desarrollo de nuevas oportunidades de negocio. Con un enfoque en la creación de estrategias comerciales y de crecimiento, asegura que cada operación esté alineada con los objetivos de Afrodita Models, garantizando así el éxito y la evolución constante del estudio."}
  ]

  const openModal = (index)=>{
    setInfo(teams[index]);
    setOpenModal(true);
  }

  return (
    <section id="team">
      <div className="container wow fadeInUp">
        <div className="row">
          <div className="col-md-12">
            <h3 className="section-title">Nuestro Equipo</h3>
            <div className="section-title-divider"></div>
            <p className="section-description ">
            En <b>Afrodita Models</b>, contamos con un equipo de trabajo apasionado, comprometido y altamente capacitado para garantizar el éxito de nuestras modelos y el crecimiento constante de nuestro estudio. Nuestro equipo está compuesto por profesionales de diversas áreas, desde especialistas en marketing y gestión de redes sociales, hasta expertos en tecnología y soporte técnico, todos trabajando en conjunto para crear un ambiente seguro, dinámico y profesional.
            </p>
            <p>
            Nos enfocamos en brindar una experiencia personalizada y de calidad, asegurando que cada modelo reciba la atención, formación y apoyo necesarios para alcanzar sus metas. La comunicación abierta y el respeto mutuo son pilares fundamentales en nuestro equipo, lo que nos permite adaptarnos a las necesidades individuales de nuestras modelos y asegurar que siempre cuenten con las mejores herramientas y recursos para destacar en la industria.
            </p>
            <p>
            En <b>Afrodita Models</b>, creemos en el trabajo en equipo y en el poder de la colaboración para crear un entorno donde todos podamos crecer y prosperar juntos.
            </p>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-3">
            <div className="member cursor-pointer" onClick={()=>openModal(0)}>
              <div className="pic">
                <img src="img/4.webp" alt="" />
              </div>
              <h4>Luis Muñoz</h4>
              <span>Fundador</span>
            </div>
          </div>

          <div className="col-md-3">
            <div className="member cursor-pointer" onClick={()=>openModal(1)}>
              <div className="pic">
                <img src="img/5.webp" alt="" />
              </div>
              <h4>Santiago Perdomo</h4>
              <span>Fundador</span>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}
