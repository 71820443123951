import { useContext } from "react";
import { ShowInfoModalContext } from "../../context/showInfoModal";

export default function ModalImag() {

  const { openModal, setOpenModal, info } = useContext(ShowInfoModalContext);


  const handleOutsideClick = (e) => {
    setOpenModal(false);
  };

  return openModal && <div className="modal fade show" id="showImage"
    tabIndex="-1" aria-labelledby="showImage" aria-hidden="true"
    onClick={handleOutsideClick}
    style={{ display: 'block' }}
  >
    <div className="modal-dialog modal-fullscreen">
      <div className="modal-content" >
        <div className="modal-header mx-5">
          <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleOutsideClick} aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-6 about-img">
             <img src={info.img} alt="" className="w-100 h-75" style={{objectFit:'scale-down'}} />
            </div>
            <div className="col-md-6">
            <h5 className="modal-title fs-5 section-title">{info.name}</h5>
              <p className="mt-5 section-description">{info.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
} 