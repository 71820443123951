import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//import RoutePrivate from "./privateRouter";
import Home from "../pages/home";

import Login from '../pages/login';
import Register from '../pages/register';
import MultiProvider from "../context/multiProvider";
import TagManager from 'react-gtm-module';
//import Admin from './admin';
//import Transmision from "./transmision";

const cart = {};
export default function App() {
  useEffect(() => {
    const tagManagerArgs = { gtmId: 'GTM-TJSW7LVM' };
    TagManager.initialize(tagManagerArgs);
}, []);

  return (
    <MultiProvider contexts={{
      shoppingCartContext:cart
    }}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route path="*" component={Home} />
          {/* <RoutePrivate exact path="/transmision" component={Admin} /> */}
        </Switch>
      </Router>
    </MultiProvider>
  );
}
