export default function Contact() {
  return (
    <section id="contact">
      <div className="container wow fadeInUp">
        <div className="row">
          <div className="col-md-12">
            <h3 className="section-title">Contacto</h3>
            <div className="section-title-divider"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 col-md-push-2">
            <div className="info">
              <div>
                <i className="fa fa-map-marker" />
                <p>
                  Colombia - Quindio
                  <br />
                  Centro de armenia 
                  <br/>
                  Carrera 14 # 18-33 piso 3
                </p>
              </div>

              <div>
                <i className="fa fa-envelope" />
                <p>Afroditamodels.axm@gmail.com</p>
              </div>

              <div>
                <i className="fa fa-phone" />
                <p>+57 322 670 7089</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-md-push-2">
            <div className="form">
              <div id="sendmessage">Your message has been sent. Thank you!</div>
              <div id="errormessage"></div>
              <form action="mailto:afroditamodels.axm@gmail.com" method="post">
                <div className="input-group mx-3">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    placeholder="Tu nombre"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 4 chars"
                  />
                  <div className="validation"></div>
                </div>
                <div className="input-group m-3">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Tu email"
                    data-rule="email"
                    data-msg="Please enter a valid email"
                  />
                  <div className="validation"></div>
                </div>
                <div className="input-group m-3">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Asunto"
                    data-rule="minlen:4"
                    data-msg="Please enter at least 8 chars of subject"
                  />
                  <div className="validation"></div>
                </div>
                <div className="input-group m-3">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="5"
                    data-rule="required"
                    data-msg="Please write something for us"
                    placeholder="Mensaje"
                  ></textarea>
                  <div className="validation"></div>
                </div>
                <div className="text-center">
                  <button type="submit">Enviar Mensaje</button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-4 col-md-push-2">
          <div className="mapouter w-100">
            <div className="gmap_canvas w-100">
              <iframe title="map" className="gmap_iframe w-100" scrolling="no" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Armenia, carrera 14  18-33&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
              </iframe>
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}
